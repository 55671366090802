import { DateTime } from 'luxon';
import {
  CREATE_BOARD_CARD_TEMPLATE,
  GET_BOARD_CARD_TEMPLATES,
  DELETE_BOARD_CARD_TEMPLATE,
  UPDATE_BOARD_CARD_TEMPLATE,
  GET_BOARDS,
  CREATE_BOARD,
  GET_BOARD_DETAILS,
  GET_CARD_BY_ID,
  CREATE_CARD,
  UPDATE_CARD,
  MOVE_CARD,
  GET_BOARD_CARD_FILES,
  DELETE_CARD,
  TOGGLE_BOARD_LOCK,
  GET_BOARD_CARD_NOTES,
  ADD_BOARD_CARD_NOTE,
  DELETE_BOARD,
  UPDATE_BOARD,
  GET_CARD_LINKS,
  GET_CARDS_BY_LINK,
  GET_MY_CARD_TAGS,
  MARK_CARD_TAGS_AS_READ,
  NEW_TAGS_FROM_SOCKET,
  GET_CARD_EVENTS,
  GET_CARD_ATTACHMENTS,
  REORDER_CARDS,
  SWITCH_BOARD_VIEW_TYPE,
  OPEN_CARD_DRAWER,
  CLOSE_CARD_DRAWER,
  OPEN_FORM_MAP_DRAWER,
  CLOSE_FORM_MAP_DRAWER,
  OPEN_FORM_DRAWER,
  CLOSE_FORM_DRAWER,
  GET_BOARDS_LIBRARY,
  GET_BOARDS_LIBRARY_TEMPLATE,
  IMPORT_FROM_BOARD_LIBRARY,
  FIX_BOARD_INDICES,
  GET_CARD_EMAILS,
  GET_CARD_EMAIL_THREAD,
  GET_CARD_EMAIL_FILES,
  SET_BOARD_TARGET_CARD,
  SET_BOARD_FILTERS,
  CREATE_BOARD_FORM_MAPPING,
  UPDATE_BOARD_FORM_MAPPING,
  DELETE_BOARD_FORM_MAPPING,
  GET_BOARD_FORM_MAPPINGS,
  GET_BOARD_TEMPLATES,
  CREATE_BOARD_TEMPLATE,
  DELETE_BOARD_TEMPLATE,
  UPDATE_BOARD_TEMPLATE,
  MASS_UPLOAD_CARDS,
  CLEAR_SELECTED_CARD,
  SWITCH_BOARD_GANTT_VIEW_TYPE,
  SWITCH_BOARD_GANTT_ZOOM_SCALE,
  GET_CARD_SNAPSHOTS,
  CLEAR_SNAPSHOT_CARD_DATA,
  SET_SNAPSHOT_CARD_DATA,
  GET_ALL_BOARDS_DETAILS,
  CLEAR_CARD_SHADOW,
  SET_CARD_DRAGGING,
} from '../../state/actionTypes';

import { removeFromObject, updateArray, updateObject } from '../../helpers/stateHelpers';
import { getIdMap } from '../../helpers/helpers';

import Permissions from '../../auth/Permissions';

const initialState = {
  cardTemplates: {},
  boards: {},
  cardFileMap: {},
  cardNotesMap: {},
  cardLinks: [],
  cardUserMap: {},
  myCardTags: [],
  myReadCardTags: [],
  tagsPerCard: {},
  cardEvents: {},
  sortType: { type: window.localStorage.getItem('boardSortType') ?? 'default' },
  viewType: window.localStorage.getItem('boardViewType') ?? 'board',
  ganttViewType: window.localStorage.getItem('boardGanttViewType') ?? 'Month',
  ganttZoomScale: window.localStorage.getItem('boardGanttZoomScale') ?? 1,
  selectedColumns: ['name', 'start', 'end'],
  drawerConfig: {},
  formDrawerConfig: {},
  formMapDrawerConfig: {},
  formMappings: [],
  library: [],
  libraryTemplates: {},
  cardEmailMap: {},
  cardEmailThreads: {},
  cardEmailFiles: {},
  targetCard: {}, // Used to scroll to the specific card,
  filters: null,
  boardTemplates: [],
  cardAttachments: {
    instantiatedFiles: [],
    locationMap: {},
  },
  notesFileMap: {},
  boardDetailsMap: {},
  cardSnapshotMap: {},
  filteredSnapshotData: null,
  isFiltered: false,
  isDragging: false,
};

const createTagCountMap = (tags = []) => {
  const tagPerCardMap = {};
  tags.forEach((tag) => {
    const { cardId } = tag;
    const {
      [cardId]: oldCount = 0,
    } = tagPerCardMap;
    tagPerCardMap[cardId] = oldCount + 1;
  });
  return tagPerCardMap;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BOARDS_LIBRARY: {
      const {
        payload: {
          library: newLibrary = [],
        } = {},
      } = action;
      return {
        ...state,
        library: newLibrary,
      };
    }
    case GET_BOARDS_LIBRARY_TEMPLATE: {
      const {
        payload: {
          key,
          templateData: { template = {} } = {},
        } = {},
      } = action;
      const { libraryTemplates: oldLibTemplates = {} } = state;
      const newLibTemplates = { ...oldLibTemplates };
      newLibTemplates[key] = template;
      return {
        ...state,
        libraryTemplates: newLibTemplates,
      };
    }
    case GET_BOARD_CARD_TEMPLATES: {
      const {
        payload: {
          boardCardTemplates: newTemplates = {},
        } = {},
      } = action;
      return {
        ...state,
        cardTemplates: newTemplates,
      };
    }
    case CREATE_BOARD_CARD_TEMPLATE: {
      const {
        payload: {
          newCardTemplate = {},
        } = {},
      } = action;
      const newTemplates = { ...state.cardTemplates };
      newTemplates[newCardTemplate.id] = newCardTemplate;
      return {
        ...state,
        cardTemplates: newTemplates,
      };
    }
    case DELETE_BOARD_CARD_TEMPLATE: {
      const {
        payload: {
          cardTemplateId = {},
        } = {},
      } = action;
      return {
        ...state,
        cardTemplates: removeFromObject({ oldObject: state.cardTemplates, id: cardTemplateId }),
      };
    }
    case UPDATE_BOARD_CARD_TEMPLATE: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      return {
        ...state,
        cardTemplates: updateObject({ oldObject: state.cardTemplates, id, newData }),
      };
    }
    case GET_BOARDS: {
      const {
        payload: {
          boards: newBoards = {},
        } = {},
      } = action;
      return {
        ...state,
        boards: getIdMap(newBoards),
      };
    }
    case IMPORT_FROM_BOARD_LIBRARY: {
      const {
        payload: {
          newBoard = {},
          card: newCardTemplate,
        } = {},
      } = action;
      const newBoards = { ...state.boards };
      const newTemplates = { ...state.cardTemplates };

      if (newCardTemplate) {
        newTemplates[newCardTemplate.id] = newCardTemplate;
      }
      newBoards[newBoard.id] = newBoard;
      return {
        ...state,
        boards: newBoards,
        cardTemplates: newTemplates,
      };
    }
    case CREATE_BOARD: {
      const {
        payload: {
          newBoard = {},
        } = {},
      } = action;
      const newBoards = { ...state.boards };
      newBoards[newBoard.id] = newBoard;
      return {
        ...state,
        boards: newBoards,
      };
    }
    case UPDATE_BOARD: {
      const {
        payload: {
          id,
          newData = {},
          newStatuses = [],
          updatedCards,
        } = {},
      } = action;

      const updatePayload = { ...newData };
      delete updatePayload.addedUsers;
      delete updatePayload.removedUsers;

      const updatedState = {
        ...state,
        boards: updateObject({ oldObject: state.boards, id, newData: updatePayload }),
      };

      const {
        selectedBoard = {},
      } = state;

      if (selectedBoard?.id !== id) return updatedState;

      const newSelectedBoard = { ...selectedBoard };
      const {
        workflows = [],
        users = [],
      } = newData;
      newSelectedBoard.users = users;
      newSelectedBoard.statuses = newStatuses;
      newSelectedBoard.workflows = workflows;

      if (updatedCards) {
        newSelectedBoard.cards = updatedCards;
      }

      updatedState.selectedBoard = newSelectedBoard;

      return updatedState;
    }
    case DELETE_BOARD: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const newBoards = { ...state.boards };
      delete newBoards[id];
      return {
        ...state,
        boards: newBoards,
      };
    }
    case TOGGLE_BOARD_LOCK: {
      const {
        payload: {
          id,
          isLocked,
        } = {},
      } = action;
      if (!(id in state.boards)) return state;
      const newBoards = { ...state.boards };
      const {
        [id]: existingBoard = {},
      } = newBoards;
      newBoards[id] = {
        ...existingBoard,
        isLocked,
      };
      return {
        ...state,
        boards: newBoards,
      };
    }
    case GET_BOARD_DETAILS: {
      const {
        payload: {
          boardId,
          boardDetails = {},
          shouldUpdateSelectedBoard = true,
        } = {},
      } = action;

      const { boardDetailsMap: oldBoardDetailsMap = {} } = state;

      const newState = {
        ...state,
        boardDetailsMap: {
          ...oldBoardDetailsMap,
          [boardId]: boardDetails,
        },
      };

      if (shouldUpdateSelectedBoard) {
        newState.selectedBoard = boardDetails;
        newState.filters = null;
      }

      return newState;
    }
    case GET_CARD_BY_ID: {
      const {
        payload: {
          card = {},
        } = {},
      } = action;
      return {
        ...state,
        selectedCard: card,
      };
    }
    case CLEAR_SELECTED_CARD: {
      const newState = { ...state };
      delete newState.selectedCard;
      newState.cardAttachments = {
        instantiatedFiles: [],
        locationMap: {},
      };
      return newState;
    }
    case CREATE_CARD: {
      const {
        payload: {
          newCard = {},
        } = {},
      } = action;
      const { selectedBoard: oldSelectedBoard = {} } = state;
      const {
        cards: oldCards = [],
      } = oldSelectedBoard;
      const newSelectedBoard = { ...oldSelectedBoard };
      const cardIdSet = new Set(oldCards.map((card) => card.id));
      if (!cardIdSet.has(newCard.id)) newSelectedBoard.cards = oldCards.concat([newCard]);
      return {
        ...state,
        selectedBoard: newSelectedBoard,
      };
    }
    case UPDATE_CARD: {
      const {
        payload: {
          id,
          newData = {},
          addedUsers = [],
          removedUsers = [],
          originalStatusId,
          originalOrderIndex = null,
        } = {},
      } = action;
      const {
        selectedBoard: oldSelectedBoard = {},
        selectedCard: oldSelectedCard,
      } = state;
      const {
        cards: oldCards = [],
      } = oldSelectedBoard;
      const newSelectedBoard = { ...oldSelectedBoard };

      const { statusId: newStatusId } = newData;
      const statusChanged = newStatusId !== originalStatusId;

      let newCard = {};

      newSelectedBoard.cards = oldCards.map((card) => {
        const { orderIndex, id: cardId, statusId } = card;
        if (cardId === id) {
          const {
            users = [],
          } = card;
          const deleteSet = new Set(removedUsers);
          const fullUserSet = new Set(users.filter((userId) => !deleteSet.has(userId)));
          addedUsers.forEach((userId) => {
            fullUserSet.add(userId);
          });
          newCard = {
            ...card,
            ...newData,
            pin: !!newData.pin,
            users: Array.from(fullUserSet),
          };
          return newCard;
        }
        if (originalOrderIndex === null) return card;
        // Need to move up cards
        if (statusChanged && statusId === originalStatusId && orderIndex >= originalOrderIndex) {
          return { ...card, orderIndex: orderIndex - 1 };
        }
        return card;
      });

      return {
        ...state,
        selectedBoard: newSelectedBoard,
        selectedCard: oldSelectedCard?.id === id ? newCard : oldSelectedCard,
      };
    }
    case DELETE_CARD: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { selectedBoard: oldSelectedBoard = {} } = state;
      const {
        cards: oldCards = [],
      } = oldSelectedBoard;
      const newSelectedBoard = { ...oldSelectedBoard };
      newSelectedBoard.cards = oldCards.filter((card) => card.id !== id);
      return {
        ...state,
        selectedBoard: newSelectedBoard,
      };
    }
    case MASS_UPLOAD_CARDS: {
      const {
        payload: {
          newCards = [],
        } = {},
      } = action;
      const { selectedBoard: oldSelectedBoard = {} } = state;
      const {
        id: oldBoardId,
        cards: oldCards = [],
      } = oldSelectedBoard;

      const [{ boardId: newBoardId }] = newCards;

      if (oldBoardId !== newBoardId) return state;

      const newSelectedBoard = { ...oldSelectedBoard };
      const cardIdSet = new Set(oldCards.map((card) => card.id));
      const uniqueNewCards = [];
      newCards.forEach((newCard) => {
        if (!cardIdSet.has(newCard.id)) uniqueNewCards.push(newCard);
      });

      newSelectedBoard.cards = oldCards.concat(uniqueNewCards);

      return {
        ...state,
        selectedBoard: newSelectedBoard,
      };
    }
    case MOVE_CARD: {
      const {
        payload: {
          id,
          destinationId: destinationIdString,
          destinationIndex,
          sourceId: sourceIdString,
          sourceIndex,
          moverId,
          color,
          boardId,
          shadow,
        } = {},
      } = action;
      /*
        This gets executed twice when you are the one moving the card
        Once from the action and once from the socket emit.
        Since we are only incrementing and decrementing, the indices become out of sync.
        See: https://projectharbour.atlassian.net/browse/HARBOUR-1835

        We check to see if the moverId === current userId
      */

      if (moverId === Permissions.id) return state;
      const destinationId = parseInt(destinationIdString, 10);
      const sourceId = parseInt(sourceIdString, 10);
      const isSameColumn = sourceId === destinationId;
      const isMoveDown = sourceIndex < destinationIndex;
      const { selectedBoard: oldSelectedBoard = {} } = state;
      let {
        cards: oldCards = [],
      } = oldSelectedBoard;
      const newSelectedBoard = { ...oldSelectedBoard };

      const isDifferentBoard = boardId !== newSelectedBoard.id;

      if (isDifferentBoard && !shadow) {
        // Remove cards that have changed boards
        // From move board workflow
        oldCards = oldCards.filter((card) => card.id !== id);
      }

      newSelectedBoard.cards = oldCards.map((card) => {
        const { id: cardId, statusId, orderIndex } = card;
        if (cardId === id) {
          const newCard = {
            ...card,
            boardId,
            statusId: isDifferentBoard && shadow ? shadow.statusId : destinationId,
            orderIndex: isDifferentBoard && shadow ? oldCards.length : destinationIndex,
            color,
            isShadow: isDifferentBoard && shadow,
          };
          if (destinationId !== statusId) {
            newCard.lastMoveTimestamp = DateTime.local().toMillis();
          }
          return newCard;
        }
        if (!isSameColumn) {
          if (statusId === sourceId && orderIndex >= sourceIndex) {
            return { ...card, orderIndex: orderIndex - 1 };
          }
          if (statusId === destinationId && orderIndex >= destinationIndex) {
            return { ...card, orderIndex: orderIndex + 1 };
          }
        }
        if (statusId !== destinationId) return card;
        if (isMoveDown) {
          if (orderIndex > sourceIndex && orderIndex <= destinationIndex) {
            return { ...card, orderIndex: orderIndex - 1 };
          }
        } else if (orderIndex >= destinationIndex && orderIndex < sourceIndex) {
          return { ...card, orderIndex: orderIndex + 1 };
        }
        return card;
      });

      return {
        ...state,
        selectedBoard: newSelectedBoard,
      };
    }
    case GET_BOARD_CARD_FILES: {
      const {
        payload: {
          id,
          fileMap = {},
        } = {},
      } = action;
      const { cardFileMap: oldFileMap = {} } = state;
      const newFileMap = { ...oldFileMap };
      newFileMap[id] = fileMap;
      return {
        ...state,
        cardFileMap: newFileMap,
      };
    }
    case GET_CARD_EVENTS: {
      const {
        payload: {
          id,
          events = [],
        } = {},
      } = action;
      const { cardEvents: oldEvents = {} } = state;
      const newEvents = { ...oldEvents };
      newEvents[id] = events;
      return {
        ...state,
        cardEvents: newEvents,
      };
    }
    case GET_BOARD_CARD_NOTES: {
      const {
        payload: {
          id,
          notes = [],
          fileMap = {},
        } = {},
      } = action;
      const { cardNotesMap: oldNotesMap = {} } = state;
      const newNotesMap = { ...oldNotesMap };
      newNotesMap[id] = notes;
      return {
        ...state,
        cardNotesMap: newNotesMap,
        notesFileMap: fileMap,
      };
    }
    case GET_CARD_ATTACHMENTS: {
      const {
        payload: {
          cardAttachments = {},
        } = {},
      } = action;

      return {
        ...state,
        cardAttachments,
      };
    }
    case ADD_BOARD_CARD_NOTE: {
      const {
        payload = {},
      } = action;
      const { cardId } = payload;
      const { cardNotesMap: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [cardId]: existingCardNotes = [],
      } = existingNotes;
      existingNotes[cardId] = existingCardNotes.concat([payload]);
      existingNotes[cardId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        cardNotesMap: existingNotes,
      };
    }
    case GET_CARD_LINKS: {
      const {
        payload: {
          cardLinks = [],
        } = {},
      } = action;
      return {
        ...state,
        cardLinks,
      };
    }
    case GET_CARDS_BY_LINK: {
      const {
        payload: {
          cardUserMap = {},
        } = {},
      } = action;
      return {
        ...state,
        cardUserMap,
      };
    }
    case GET_MY_CARD_TAGS: {
      const {
        payload: {
          myCardTags = [],
          hasRead,
        } = {},
      } = action;
      if (hasRead) {
        return {
          ...state,
          myReadCardTags: myCardTags,
        };
      }
      return {
        ...state,
        myCardTags,
        tagsPerCard: createTagCountMap(myCardTags),
      };
    }
    case MARK_CARD_TAGS_AS_READ: {
      const {
        payload: {
          noteIds = new Set(),
        } = {},
      } = action;
      const { myCardTags: stateCardTags = [] } = state;
      const filteredTags = stateCardTags.filter((tag) => (
        !noteIds.has(tag.cardNoteId)
      ));

      return {
        ...state,
        myCardTags: filteredTags,
        tagsPerCard: createTagCountMap(filteredTags),
      };
    }
    case NEW_TAGS_FROM_SOCKET: {
      const {
        payload: {
          newTags = [],
        } = {},
      } = action;

      const { myCardTags: stateCardTags = [] } = state;
      const existingSet = new Set(stateCardTags.map((tag) => tag.id));
      const fullTags = stateCardTags.concat(
        newTags.filter((tag) => !existingSet.has(tag.id)),
      );

      return {
        ...state,
        myCardTags: fullTags,
        tagsPerCard: createTagCountMap(fullTags),
      };
    }
    case REORDER_CARDS: {
      const {
        payload: {
          type,
          field,
        } = {},
      } = action;

      const newSortType = {
        type,
        field: field ?? null,
      };

      return { ...state, sortType: newSortType };
    }
    case SWITCH_BOARD_VIEW_TYPE: {
      const {
        payload: {
          viewType,
        } = {},
      } = action;
      return { ...state, viewType };
    }
    case SWITCH_BOARD_GANTT_VIEW_TYPE: {
      const {
        payload: {
          ganttViewType,
        } = {},
      } = action;
      return { ...state, ganttViewType };
    }
    case SWITCH_BOARD_GANTT_ZOOM_SCALE: {
      const {
        payload: {
          ganttZoomScale,
        } = {},
      } = action;
      return { ...state, ganttZoomScale };
    }
    case OPEN_CARD_DRAWER: {
      const {
        payload: {
          drawerConfig,
        } = {},
      } = action;
      return { ...state, drawerConfig: { ...drawerConfig, visible: true } };
    }
    case CLOSE_CARD_DRAWER: {
      return { ...state, drawerConfig: {} };
    }
    case OPEN_FORM_MAP_DRAWER: {
      const {
        payload: {
          drawerConfig,
        } = {},
      } = action;
      return { ...state, formMapDrawerConfig: { ...drawerConfig, visible: true } };
    }
    case CLOSE_FORM_MAP_DRAWER: {
      return { ...state, formMapDrawerConfig: {} };
    }
    case OPEN_FORM_DRAWER: {
      const {
        payload: {
          drawerConfig,
        } = {},
      } = action;
      return { ...state, formDrawerConfig: { ...drawerConfig, visible: true } };
    }
    case CLOSE_FORM_DRAWER: {
      return { ...state, formDrawerConfig: {} };
    }
    case FIX_BOARD_INDICES: {
      const {
        payload: {
          boardId,
          cards: cardIndexMap = {},
        } = {},
      } = action;
      const { selectedBoard: oldSelectedBoard = {} } = state;
      if (!oldSelectedBoard || oldSelectedBoard.id !== boardId) return state;
      const newSelectedBoard = { ...oldSelectedBoard };
      const { cards: oldCards = [] } = newSelectedBoard;
      newSelectedBoard.cards = oldCards.map((card) => {
        const { id: cardId, orderIndex: oldOrderIndex } = card;
        if (!(cardId in cardIndexMap)) return card;
        const {
          [cardId]: newOrderIndex = oldOrderIndex,
        } = cardIndexMap;
        return {
          ...card,
          orderIndex: newOrderIndex,
        };
      });
      return {
        ...state,
        selectedBoard: newSelectedBoard,
      };
    }
    case GET_CARD_EMAILS: {
      const {
        payload: {
          id,
          emails = [],
        } = {},
      } = action;
      const { cardEmailMap: oldMap = {} } = state;
      const newMap = { ...oldMap };
      newMap[id] = emails;
      return {
        ...state,
        cardEmailMap: newMap,
      };
    }
    case GET_CARD_EMAIL_THREAD: {
      const {
        payload: {
          threadId,
          thread = [],
        } = {},
      } = action;
      const { cardEmailThreads: oldMap = {} } = state;
      const newMap = { ...oldMap };
      newMap[threadId] = thread;
      return {
        ...state,
        cardEmailThreads: newMap,
      };
    }
    case GET_CARD_EMAIL_FILES: {
      const {
        payload: {
          file = {},
          fullFile,
        } = {},
      } = action;
      const { attachmentId } = file;
      if (!attachmentId) return state;
      const { cardEmailFiles: oldMap = {} } = state;
      const newMap = { ...oldMap };
      newMap[attachmentId] = { ...file, jsFileObject: fullFile };
      return {
        ...state,
        cardEmailFiles: newMap,
      };
    }
    case SET_BOARD_TARGET_CARD: {
      const {
        payload: {
          cardId,
          boardId,
          statusId,
        } = {},
      } = action;
      return {
        ...state,
        targetCard: {
          cardId,
          boardId,
          statusId,
        },
      };
    }
    case SET_BOARD_FILTERS: {
      const {
        payload: {
          filters,
        } = {},
      } = action;
      return {
        ...state,
        filters,
      };
    }
    case GET_BOARD_FORM_MAPPINGS: {
      const {
        payload: {
          formMappings = {},
        } = {},
      } = action;
      return {
        ...state,
        formMappings,
      };
    }
    case CREATE_BOARD_FORM_MAPPING: {
      const {
        payload: {
          newMapping = {},
        } = {},
      } = action;
      const newMappings = [...state.formMappings];
      newMappings.push(newMapping);
      return {
        ...state,
        formMappings: newMappings,
      };
    }
    case UPDATE_BOARD_FORM_MAPPING: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      return {
        ...state,
        formMappings: updateArray({ oldArray: state.formMappings, id, newData }),
      };
    }
    case DELETE_BOARD_FORM_MAPPING: {
      const {
        payload: {
          mappingId = {},
        } = {},
      } = action;
      const newMappings = [...state.formMappings];
      return {
        ...state,
        formMappings: Array.from(newMappings).filter((mapping) => mapping.id !== mappingId),
      };
    }
    case GET_BOARD_TEMPLATES: {
      const {
        payload: {
          boardTemplates: newTemplates = [],
        } = {},
      } = action;
      return {
        ...state,
        boardTemplates: newTemplates,
      };
    }
    case CREATE_BOARD_TEMPLATE: {
      const {
        payload: {
          boardTemplate = {},
        } = {},
      } = action;
      const newTemplates = state.boardTemplates.slice();
      const templateIdSet = new Set(state.boardTemplates.map((template) => template.id));
      if (!templateIdSet.has(boardTemplate.id)) newTemplates.push(boardTemplate);
      return {
        ...state,
        boardTemplates: newTemplates,
      };
    }
    case DELETE_BOARD_TEMPLATE: {
      const {
        payload: {
          id: deletedId,
        } = {},
      } = action;
      return {
        ...state,
        boardTemplates: state.boardTemplates.filter((b) => b.id !== deletedId),
      };
    }
    case UPDATE_BOARD_TEMPLATE: {
      const {
        payload: {
          boardTemplate = {},
        } = {},
      } = action;
      return {
        ...state,
        boardTemplates: state.boardTemplates.map(
          (oldTemplate) => (oldTemplate.id === boardTemplate.id
            ? { ...oldTemplate, ...boardTemplate }
            : oldTemplate),
        ),
      };
    }
    case GET_CARD_SNAPSHOTS: {
      const {
        payload: {
          cardId,
          snapshots,
        } = {},
      } = action;
      return {
        ...state,
        cardSnapshotMap: {
          ...state.cardSnapshotMap,
          [cardId]: snapshots,
        },
      };
    }
    case CLEAR_SNAPSHOT_CARD_DATA: {
      return {
        ...state,
        isFiltered: false,
        filteredSnapshotData: null,
      };
    }
    case SET_SNAPSHOT_CARD_DATA: {
      const {
        payload: {
          filteredData,
        } = {},
      } = action;
      return {
        ...state,
        isFiltered: true,
        filteredSnapshotData: filteredData,
      };
    }
    case GET_ALL_BOARDS_DETAILS: {
      const {
        payload: {
          data = [],
        },
      } = action;
      const newDetails = { ...state.boardDetailsMap };
      data.forEach((board) => {
        newDetails[board.id] = {
          ...newDetails[board.id],
          ...board,
        };
      });
      return {
        ...state,
        boardDetailsMap: newDetails,
      };
    }
    case CLEAR_CARD_SHADOW: {
      const {
        payload: {
          boardId,
          cardId,
        } = {},
      } = action;
      const {
        selectedBoard = {},
      } = state;
      if (boardId !== selectedBoard?.id) return state;
      const newSelectedBoard = { ...selectedBoard };
      newSelectedBoard.cards = selectedBoard?.cards?.filter((card) => (
        card.id !== cardId
      )) ?? [];
      return {
        ...state,
        selectedBoard: newSelectedBoard,
      };
    }
    case SET_CARD_DRAGGING: {
      const {
        payload: {
          isDragging,
        } = {},
      } = action;
      return {
        ...state,
        isDragging,
      };
    }
    default:
      return state;
  }
};
