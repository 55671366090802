import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Select, Switch } from 'antd';
import PropTypes from 'prop-types';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { currencyFormatter } from '../../../helpers/helpers';

import ScheduleOfValuesPDFPreviewForm from './ScheduleOfValuesPDFPreviewForm';
import {
  getBase64ImageFromURL,
  getTableRow,
  getChangeOrderSummary,
  getNonHoldbackSummary,
  basePdfDefinition,
  getSubContractCOHeader,
} from './sov.pdf.helpers';

import { getCompanyImageURL } from '../../../settings/state/settings.actions';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const baseSections = [
  {
    label: 'Base Contract',
    value: 'baseContract',
  }, {
    label: 'Sub-Contracts',
    value: 'subContract',
  }, {
    label: 'Changes',
    value: 'changeOrder',
  }, {
    label: 'Non-Holdback Items',
    value: 'nonHoldback',
  },
];

function ScheduleOfValuesPDFPreview({
  projectId,
  templateId,
  baseContractValues = [],
  subContractValues = [],
  liveSummaryValues: [liveSummaryRow = {}] = [],
  subContractCOs = {},
  changeOrderValues = [],
  nonHoldbackValues = [],
  sectionValueMap,
  currentHoldbackPercentage = 0,
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const companyImageURL = useSelector((state) => state.settings.company.companyImageURL);
  const scheduleOfValuePDFRows = useSelector((state) => (
    state.projects.scheduleOfValuePDFRows || []
  ));
  const scheduleOfValueSections = useSelector((state) => state.projects.scheduleOfValueSections);
  const [pdfDefinition, setPdfDefinition] = useState(null);
  const [isCompanyImageOn, setIsCompanyImageOn] = useState(!!companyImageURL);
  const [selectedPDFSections, setSelectedPDFSections] = useState([]);

  const pdfRef = useRef(null);

  const pdfSectionOptions = useMemo(() => (
    baseSections.concat(scheduleOfValueSections.map((section) => ({
      label: section.name,
      value: section.id,
    })))
  ), [scheduleOfValueSections]);

  useEffect(() => {
    const existingPDFSections = window.localStorage.getItem(`scheduleofvalue-pdf-sections-${projectId}`);
    if (existingPDFSections) {
      setSelectedPDFSections(JSON.parse(existingPDFSections));
      return;
    }

    // If no existing config set the default sections
    setSelectedPDFSections(pdfSectionOptions.map((section) => section.value));
  }, [projectId, pdfSectionOptions]);

  useEffect(() => {
    dispatch(getCompanyImageURL());
  }, []);

  useEffect(() => {
    setIsCompanyImageOn(!!companyImageURL);
  }, [companyImageURL]);

  const onCompanyImageToggled = (e) => {
    setIsCompanyImageOn(e);
  };

  useEffect(() => {
    if (visible && pdfDefinition && pdfRef.current) {
      const pdfDocGenerator = pdfMake.createPdf(pdfDefinition);
      pdfDocGenerator.getDataUrl((dataUrl) => {
        pdfRef.current.src = dataUrl;
      });
    }
  }, [visible, pdfDefinition]);

  useEffect(() => {
    const generatePDFDefinition = async () => {
      const newPdfDefinition = {
        ...basePdfDefinition,
        content: [],
      };

      const pdfHeader = {
        columns: [],
        style: 'pdfHeader',
      };

      if (isCompanyImageOn && companyImageURL && companyImageURL) {
        let image;
        try {
          image = await getBase64ImageFromURL(companyImageURL);
        } catch (e) {
          // silently fail
        }

        if (image) {
          pdfHeader.columns.push({
            image,
            width: 150,
            maxWidth: 150,
            maxHeight: 100,
          });
        }
      }

      if (scheduleOfValuePDFRows.length) {
        const pdfHeaderRows = scheduleOfValuePDFRows.map((row) => [
          {
            text: row.key, bold: true, alignment: 'right', style: 'tableCell',
          },
          { text: row.value, alignment: 'left', style: 'tableCell' },
        ]);

        pdfHeader.columns.push({
          table: {
            body: [
              ...pdfHeaderRows,
            ],
          },
          layout: 'noBorders',
          style: 'pdfHeaderTable',
        });
      }

      newPdfDefinition.content.push(pdfHeader);

      const tableHeaders = [
        { text: 'Item #', style: 'tableHeader' },
        { text: 'Description', style: 'tableHeader' },
        { text: 'Contract Amount', style: 'tableHeader' },
        { text: '% Complete to this billing', style: 'tableHeader' },
        { text: 'Progress to Date', style: 'tableHeader' },
        { text: 'Previous Billings', style: 'tableHeader' },
        { text: 'This Invoice', style: 'tableHeader' },
      ];

      // Do a deep copy to get table headers since pdfmake alters the columns
      const changeOrderTableHeaders = JSON.parse(
        JSON.stringify(tableHeaders.toSpliced(1, 0, { text: 'Number', style: 'tableHeader' })),
      );

      const nonHoldbackTableHeaders = JSON.parse(
        JSON.stringify(tableHeaders),
      );

      const selectedPDFSectionSet = new Set(selectedPDFSections);

      const showBaseContract = baseContractValues.length && selectedPDFSectionSet.has('baseContract');
      const showSubContract = subContractValues.length && selectedPDFSectionSet.has('subContract');
      const showNonHoldback = selectedPDFSectionSet.has('nonHoldback');
      const showChanges = selectedPDFSectionSet.has('changeOrder');
      const displaySections = [];

      scheduleOfValueSections.forEach((section) => {
        if (selectedPDFSectionSet.has(section.id) && sectionValueMap[section.id]?.length) {
          displaySections.push(section);
        }
      });

      if (
        showBaseContract
        || showSubContract
        || displaySections?.length
      ) {
        const baseTable = {
          headerRows: 1,
          body: [],
          widths: ['5%', '32%', '12%', '15%', '12%', '12%', '12%'],
        };

        let baseTableRows = [];

        if (showSubContract) {
          baseTableRows = baseTableRows.concat(subContractValues.map((c) => getTableRow(c, false)));
          baseTableRows.push([
            {
              text: 'TOTAL SUB-CONTRACTS',
              style: 'tableHeader',
              alignment: 'right',
              colSpan: 2,
              noWrap: true,
            },
            {},
            {
              text: currencyFormatter(liveSummaryRow.totalSubContractAmount),
              style: 'tableHeader',
              alignment: 'left',
            },
            {
              text: `${(
                liveSummaryRow.totalSubContractAmount
                  ? (
                    (liveSummaryRow.subContractProgressToDate
                      / liveSummaryRow.totalSubContractAmount)
                    * 100
                  )
                  : 0
              ).toFixed(2)}%`,
              style: 'tableHeader',
            },
            {
              text: currencyFormatter(liveSummaryRow.subContractProgressToDate),
              style: 'tableHeader',
              alignment: 'left',
            },
            {
              text: currencyFormatter(liveSummaryRow.subContractPreviousBillings),
              style: 'tableHeader',
              alignment: 'left',
            },
            {
              text: currencyFormatter(liveSummaryRow.subContractInvoiceAmount),
              style: 'tableHeader',
              alignment: 'left',
            },
          ]);
        }

        if (showBaseContract) {
          baseTableRows = baseTableRows.concat(
            baseContractValues.map((c) => getTableRow(c, false)),
          );
          baseTableRows.push([
            {
              text: 'TOTAL BASE CONTRACT',
              style: 'tableHeader',
              alignment: 'right',
              colSpan: 2,
              noWrap: true,
            },
            {},
            {
              text: currencyFormatter(liveSummaryRow.totalContractAmount),
              style: 'tableHeader',
              alignment: 'left',
            },
            {
              text: `${(
                liveSummaryRow.totalContractAmount
                  ? (
                    (liveSummaryRow.baseContractProgressToDate / liveSummaryRow.totalContractAmount)
                    * 100
                  )
                  : 0
              ).toFixed(2)}%`,
              style: 'tableHeader',
            },
            {
              text: currencyFormatter(liveSummaryRow.baseContractProgressToDate),
              style: 'tableHeader',
              alignment: 'left',
            },
            {
              text: currencyFormatter(liveSummaryRow.baseContractPreviousBillings),
              style: 'tableHeader',
              alignment: 'left',
            },
            {
              text: currencyFormatter(liveSummaryRow.baseContractInvoiceAmount),
              style: 'tableHeader',
              alignment: 'left',
            },
          ]);
        }

        if (displaySections) {
          displaySections.forEach((section) => {
            if (!sectionValueMap[section.id]?.length) return;
            baseTableRows = baseTableRows.concat(
              sectionValueMap[section.id].map((c) => getTableRow(c, false)),
            );
            baseTableRows.push([
              {
                text: `TOTAL ${section.name.toUpperCase()}`,
                style: 'tableHeader',
                alignment: 'right',
                colSpan: 2,
                noWrap: true,
              },
              {},
              {
                text: currencyFormatter(0),
                style: 'tableHeader',
                alignment: 'left',
              },
              {
                text: `${(
                  liveSummaryRow.totalContractAmount && section.shouldBeInCalculations
                    ? (
                      (liveSummaryRow[`${section.id}ProgressToDate`] / liveSummaryRow.totalContractAmount)
                      * 100
                    )
                    : 0
                ).toFixed(2)}%`,
                style: 'tableHeader',
              },
              {
                text: currencyFormatter(liveSummaryRow[`${section.id}ProgressToDate`]),
                style: 'tableHeader',
                alignment: 'left',
              },
              {
                text: currencyFormatter(liveSummaryRow[`${section.id}PreviousBillings`]),
                style: 'tableHeader',
                alignment: 'left',
              },
              {
                text: currencyFormatter(liveSummaryRow[`${section.id}InvoiceAmount`]),
                style: 'tableHeader',
                alignment: 'left',
              },
            ]);
          });
        }

        if (currentHoldbackPercentage && showNonHoldback) {
          baseTableRows.push(getNonHoldbackSummary(liveSummaryRow));
        }

        if (showChanges) baseTableRows.push(getChangeOrderSummary(liveSummaryRow));

        baseTableRows.push([
          {
            text: 'CONTRACT TOTAL',
            style: 'tableHeader',
            alignment: 'right',
            colSpan: 2,
          },
          {},
          {
            text: currencyFormatter(liveSummaryRow.totalContractValue),
            style: 'tableHeader',
            alignment: 'left',
          },
          {
            text: `${(
              liveSummaryRow.totalContractValue ? (
                ((
                  liveSummaryRow.changeOrderProgressToDate
                  + liveSummaryRow.baseContractProgressToDate
                  + liveSummaryRow.subContractProgressToDate
                  + liveSummaryRow.nonHoldbackProgressToDate
                ) / liveSummaryRow.totalContractValue) * 100
              ) : 0
            ).toFixed(2)}%`,
            style: 'tableHeader',
          },
          {
            text: currencyFormatter(
              liveSummaryRow.changeOrderProgressToDate
                + liveSummaryRow.baseContractProgressToDate
                + liveSummaryRow.subContractProgressToDate
                + liveSummaryRow.nonHoldbackProgressToDate,
            ),
            style: 'tableHeader',
            alignment: 'left',
          },
          {
            text: currencyFormatter(liveSummaryRow.totalBilledToDate),
            style: 'tableHeader',
            alignment: 'left',
          },
          {
            text: currencyFormatter(liveSummaryRow.invoiceAmount),
            style: 'tableHeader',
            alignment: 'left',
          },
        ]);

        baseTable.body.push(tableHeaders, ...baseTableRows);
        newPdfDefinition.content.push({
          table: baseTable,
        });
      }

      if (
        baseContractValues.length
        || changeOrderValues.length
        || subContractValues.length
        || Object.values(sectionValueMap).length > 0
      ) {
        const footerSummary = {
          columns: [
            { width: '*', text: '' },
          ],
        };

        const {
          relevantCustomSectionProgressToDate = 0,
          relevantCustomSectionInvoiceAmount = 0,
        } = scheduleOfValueSections.reduce(
          (acc, section) => {
            if (section.shouldBeInCalculations) {
              acc.relevantCustomSectionProgressToDate += liveSummaryRow[`${section.id}ProgressToDate`] ?? 0;
            }

            acc.relevantCustomSectionInvoiceAmount += liveSummaryRow[`${section.id}InvoiceAmount`] ?? 0;
            return acc;
          }, {
            relevantCustomSectionProgressToDate: 0,
            relevantCustomSectionInvoiceAmount: 0,
          });

        const footerRows = [
          [
            { text: '' },
            { text: 'Summary', style: 'tableHeader', color: 'red' },
            { text: '' },
          ],
          [
            { text: '' },
            { text: 'Total completed to date:', style: 'tableHeader', alignment: 'right' },
            {
              text: currencyFormatter(
                liveSummaryRow.changeOrderProgressToDate
                  + liveSummaryRow.baseContractProgressToDate
                  + liveSummaryRow.subContractProgressToDate
                  + liveSummaryRow.nonHoldbackProgressToDate
                  + relevantCustomSectionProgressToDate,
              ),
              style: 'tableHeader',
              alignment: 'left',
            },
          ],
          [
            { text: '' },
            { text: 'Less previous billings:', style: 'tableHeader', alignment: 'right' },
            { text: currencyFormatter(liveSummaryRow.totalBilledToDate), style: 'tableHeader', alignment: 'left' },
          ],
          [
            { text: '' },
            { text: 'Holdback Excluded Billing:', style: 'tableHeader', alignment: 'right' },
            { text: currencyFormatter(liveSummaryRow.nonHoldbackInvoiceAmount), style: 'tableHeader', alignment: 'left' },
          ],
          [
            { text: '' },
            { text: 'Holdback Included Billing:', style: 'tableHeader', alignment: 'right' },
            {
              text: currencyFormatter(
                liveSummaryRow.baseContractInvoiceAmount
              + liveSummaryRow.changeOrderInvoiceAmount
              + liveSummaryRow.subContractInvoiceAmount
              + relevantCustomSectionInvoiceAmount,
              ),
              style: 'tableHeader',
              alignment: 'left',
            },
          ],
          [
            { text: '' },
            { text: 'Total this billing:', style: 'tableHeader', alignment: 'right' },
            { text: currencyFormatter(liveSummaryRow.invoiceAmount), style: 'tableHeader', alignment: 'left' },
          ],
        ];

        if (currentHoldbackPercentage) {
          footerRows.push([
            { text: 'Less holdback:', style: 'tableHeader', alignment: 'right' },
            {
              text: `${liveSummaryRow.holdbackPercentage * 100}%`,
              style: 'tableHeader',
              fillColor: 'yellow',
            },
            {
              text: currencyFormatter(
                liveSummaryRow.holdbackPercentage * (
                  liveSummaryRow.baseContractInvoiceAmount
                  + liveSummaryRow.changeOrderInvoiceAmount
                  + liveSummaryRow.subContractInvoiceAmount
                ),
              ),
              style: 'tableHeader',
              alignment: 'left',
            },
          ]);
        }

        footerRows.push([
          { text: '' },
          { text: 'Invoice Total:', style: 'tableHeader', alignment: 'right' },
          {
            text: currencyFormatter(
              liveSummaryRow.invoiceAmount
               - (liveSummaryRow.holdbackPercentage * (liveSummaryRow.invoiceAmount - liveSummaryRow.nonHoldbackInvoiceAmount)),
            ),
            style: 'tableHeader',
            alignment: 'left',
          },
        ]);

        footerSummary.columns.push({
          width: 'auto',
          unbreakable: true,
          layout: 'noBorders',
          table: {
            body: footerRows,
          },
        });

        newPdfDefinition.content.push(footerSummary);
      }

      if ((changeOrderValues.length || Object.keys(subContractCOs).length) && showChanges) {
        const changeOrderTable = {
          headerRows: 1,
          body: [],
          widths: ['5%', '5%', '32%', '12%', '15%', '12%', '12%', '12%'],
        };

        let changeOrderRows = [];

        subContractValues.forEach((subContract) => {
          const { rowId, description } = subContract;
          const {
            [rowId]: {
              changes: subContractChanges = [],
            } = {},
          } = subContractCOs;
          if (subContractChanges.length === 0) return;
          changeOrderRows.push(getSubContractCOHeader(description));
          changeOrderRows = changeOrderRows.concat(
            subContractChanges.map((c) => getTableRow(c, true)),
          );
        });

        if (changeOrderRows.length) {
          changeOrderRows.push(getSubContractCOHeader('CHANGES'));
        }

        changeOrderRows = changeOrderRows.concat(
          changeOrderValues.map((c) => getTableRow(c, true)),
        );
        changeOrderRows.push(
          getChangeOrderSummary(liveSummaryRow, true),
        );

        changeOrderTable.body.push(changeOrderTableHeaders, ...changeOrderRows);
        newPdfDefinition.content.push({
          table: changeOrderTable,
          pageBreak: 'before',
        });
      }

      if (nonHoldbackValues.length && currentHoldbackPercentage && showNonHoldback) {
        const nonHoldbackTable = {
          headerRows: 1,
          body: [],
          widths: ['5%', '32%', '12%', '15%', '12%', '12%', '12%'],
        };

        const nonHoldbackRows = nonHoldbackValues.map((c) => getTableRow(c, false));
        nonHoldbackRows.push(
          getNonHoldbackSummary(liveSummaryRow),
        );

        nonHoldbackTable.body.push(nonHoldbackTableHeaders, ...nonHoldbackRows);
        newPdfDefinition.content.push({
          table: nonHoldbackTable,
          pageBreak: 'before',
        });
      }

      setPdfDefinition(newPdfDefinition);
    };

    generatePDFDefinition();
  }, [
    baseContractValues,
    subContractValues,
    changeOrderValues,
    liveSummaryRow,
    nonHoldbackValues,
    scheduleOfValuePDFRows,
    currentHoldbackPercentage,
    isCompanyImageOn,
    selectedPDFSections,
  ]);

  return (
    <Drawer
      title="Review PDF"
      visible={visible}
      onClose={onClose}
      width={1100}
    >
      <Form.Item name="companyImage" label="Load in company image">
        <Switch checked={isCompanyImageOn} onChange={onCompanyImageToggled} />
      </Form.Item>
      <Form.Item name="pdfSections" label="PDF Sections">
        <Select
          mode="multiple"
          defaultValue={selectedPDFSections}
          value={selectedPDFSections}
          onChange={(value) => {
            setSelectedPDFSections(value);
            window.localStorage.setItem(`scheduleofvalue-pdf-sections-${projectId}`, JSON.stringify(value));
          }}
          options={pdfSectionOptions}
        />
      </Form.Item>
      <ScheduleOfValuesPDFPreviewForm projectId={projectId} templateId={templateId} />
      <iframe title="Contract PDF" ref={pdfRef} className="sov-pdf-preview-frame" />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
ScheduleOfValuesPDFPreview.propTypes = {
  projectId: PropTypes.string,
  templateId: PropTypes.string,
  baseContractValues: PropTypes.array.isRequired,
  subContractValues: PropTypes.array.isRequired,
  liveSummaryValues: PropTypes.array.isRequired,
  changeOrderValues: PropTypes.array.isRequired,
  nonHoldbackValues: PropTypes.array.isRequired,
  currentHoldbackPercentage: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  subContractCOs: PropTypes.object.isRequired,
};

ScheduleOfValuesPDFPreview.defaultProps = {
  visible: false,
  projectId: null,
  templateId: null,
};

export default ScheduleOfValuesPDFPreview;
